import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Cards from './components/Cards';
import { schools } from './schools';
import { useState } from 'react';

function App() {
  const [schoolsList, setSchoolsList] = useState(schools)

  const list = Object.keys(schoolsList).map(iteration => {
    return (
      <Cards
        key={iteration} 
        mykey={iteration}
        nom={schoolsList[iteration].title}
        logo={schoolsList[iteration].logo}
        tel={schoolsList[iteration].tel}
        adresse={schoolsList[iteration].adresse}
        cp={schoolsList[iteration].cp}
        mail={schoolsList[iteration].email}
        ville={schoolsList[iteration].ville}
        url={schoolsList[iteration].url}
      />
    )
  })

  return (
   <>
    <div className="slide" id="home">
      <Header />
      <div className="container">
            <div className="wrapper">
                <div className="logo-info">
                  <img src="/images/LogoCSRWaPi.svg" alt="Logo CSR WaPi"/>
                  <div className="date">
                    du 17/04 au 28/04 <span>2023</span>
                  </div>
                </div>
                <div id="jumbotron">
                  <div className="contain">
                    <h1 className="title">PROMOTION SOCIALE</h1>
                    <h2>L'ENSEIGNEMENT TOUT AU LONG DE LA VIE</h2>
                    <div className="texte">
                      <p>C.S.R - WAPI - Promotion Sociale de Wallonie Picarde.</p>
                      <p>Un enseignement pour Adultes tout au long de la vie & Formations Continuées</p>
                    </div>
                    <a className="btn blue" href="#schools">Voir les établissements</a>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div className="slide" id="schools">
      <div className="wrapper">
            <h2>NOS ECOLES DE PROMOTION SOCIALE EN WALLONIE PICARDE</h2>
            <div id="myschools">
              {list}
            </div>
      </div>
    </div>
    <Footer />
   </>
  );
}

export default App;
