import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Footer = (props) => {
    return ( 
        <>
          <footer>
               <div className="wrapper">
                    <div className="navicopy">
                         <HashLink to="/#home">Accueil</HashLink>&nbsp;|&nbsp;
                         <HashLink to="/#schools">Etablissements</HashLink>
                         &nbsp;
                         <span>
                              &copy; CSR WAPI 2023 - Tous droits réservés
                         </span>
                    </div>
                    <div className="foot-logo">
                         <img src="/images/eps_logo.png" alt="Logo Enseignement de Promotion Sociale" />
                         <img src="/images/logo-fwb.png" alt="Logo de la Fédération Wallonie-Bruxelles" />
                    </div>
               </div>
          </footer>
        </>
     );
}
 
export default Footer;