export const schools = {
    ipeps : {
        title: "IPEPS PROM'SOC WaPi - Tournai / Leuze",
        nom: "IPEPS PROM'SOC WaPi - Tournai / Leuze",
        adresse: "Rue Paul Pastur 2", 
        cp: 7500,
        ville: "Tournai",
        logo: "ipeps_logo.png",
        tel: "+32 69 25 37 32",
        email: "ipeps.tournai@hainaut.be",
        description: "<div class='adresse'>Rue Paul Pastur 49, 7900 Leuze-en-Hainaut</div><div class='tel'>Téléphone: +32 69 67 21 26</div><div class='mail'>ipeps.leuze@hainaut.be</div><p>Cours du Soir et / ou du Jour Enseignement pour Adultes - Formation continuée</p>",
        url: "https://www.jetudie.be/psw"
    },
    eafcPeruwelz : {
        title: "EAFC - Péruwelz",
        nom: "EAFC - Péruwelz",
        adresse: "Boulevard Léopold III 40", 
        cp: 7600,
        ville: "Péruwelz",
        logo: "eafc_logo.png",
        tel: "+32 69 77 10 35",
        email: "secretariat@epsperuwelz.be",
        description: "",
        url: "https://www.epsperuwelz.be"
    },
    epse : {
        title: "EPSE - Enghien",
        nom: "Enseignement de promotion sociale d'Enghien",
        adresse: "Rue du village 50", 
        cp: 7850,
        ville: "Enghien",
        logo: "logo_epse.png",
        tel: "+32 23 95 60 23",
        email: "info@epse.be",
        description: "<p>Venez vous immerger dans le quotidien de nos étudiant-es</p><strong>Inscription obligatoire via info@epse.be</strong><p>L'EPSE est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants </p>",
        url: "https://www.epse.be"
    },
    ish : {
        title: "Institut Saint-Henri de Comines",
        nom: "Institut Saint-Henri de Comines",
        adresse: "Rue du Commerce 21", 
        cp: 7780,
        ville: "Comines - Warneton",
        logo: "ish_logo.png",
        tel: "+32 56 56 00 60",
        email: "institut.saint.henri@skynet.be",
        description: "",
        url: "https://www.saint-henri.be"
    },
    sthenri : {
        title: "Institut Saint-Henri de Mouscron",
        nom: "Institut Saint-Henri de Mouscron",
        adresse: "Avenue Royale 50", 
        cp: 7700,
        ville: "Mouscron",
        logo: "sthenri_logo.png",
        tel: "+32 56 85 57 28",
        email: "promsoc@sthenri.be",
        description: "<p>Cours dispensés essentiellement en soirée de langue (anglais, espagnol, néerlandais), de CAP, d'aide-soignant (en journée), d'habillement, de loisirs créatifs, de gestion de base et de biérologie.</p><div class='adresse'>Rue Abbé Dropsy, 2 à 7540 Kain</div><div class='tel'>Téléphone: +32 69 22 78 62</div><div class='mail'>Mail: promsoc.kain@sthenri.be</div><p>Cours de langues (allemand, anglais, espagnol, italien, néerlandais) dispensés essentiellement en soirée </p>",
        url: "https://www.saint-henri.be"
    },
    eafcTournai : {
        title: "EAFC - Tournai",
        nom: "EAFC - Tournai",
        adresse: "Rue des Moulins 4", 
        cp: 7500,
        ville: "Tournai",
        logo: "eafct_logo.png",
        tel: "+32 69 22 48 41",
        email: "info@eafc-tournai.be",
        description: "<p>L'EAFC Tournai Eurométropole est un lieu où les adultes peuvent apprendre et se former dans une atmosphère accueillante et bienveillante. Nous offrons une variété de formations, allant des cours de base en Français, Langues étrangères à des formations plus spécialisées dans les domaines de la santé, du social, de l'aide aux personnes et de l'éducation.</p><p>Nous sommes fiers de faire partie du réseau Wallonie-Bruxelles Enseignement ainsi que de l'ensemble des établissements d'enseignement de promotion sociale dans notre région.</p>",
        url: "https://www.eafc-tournai.be"
    },
    epsl : {
        title: "EPSL - Lessines",
        nom: "EPSL - Lessines",
        adresse: "Ancine Chemin d'Ollignies 2", 
        cp: 7860,
        ville: "Lessines",
        logo: "epsl_logo.png",
        tel: "+32 68 33 28 26",
        email: "promsoc.lessines@gmail.com",
        description: "",
        url: "https://www.epslessines.com"
    },
    eafcAth : {
        title: "EAFC - Ath",
        nom: "EAFC - Ath",
        adresse: "Rue Carton 5", 
        cp: 7800,
        ville: "Ath",
        logo: "eafcath_logo.png",
        tel: "+32 68 28 17 44",
        email: "eafc.ath@gmail.com",
        description: "",
        url: "https://www.eafc-ath.com"
    },
    eafcMouscron : {
        title: "EAFC - Mouscron",
        nom: "EAFC - Mouscron",
        adresse: "Place de la Justice 1", 
        cp: 7700,
        ville: "Mouscron",
        logo: "eafcmouscron.png",
        tel: "+32 56 84 23 72",
        email: "contact@eafcm.be",
        description: "",
        url: "https://www.iepsm.be"
    },
    coupeCouture : {
        title: "Coupe Couture - Tournai",
        nom: "Coupe Couture - Tournai",
        adresse: "Rue Galterie Saint-Jean 15", 
        cp: 7500,
        ville: "Tournai",
        logo: "ecoleCoupeCouture.png",
        tel: "+32 69 21 30 76",
        email: "ecole.coupecouture@tournai.be",
        description: "",
        url: "https://www.facebook.com/Schoepskatia"
    }
}