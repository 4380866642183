import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false)

    useEffect(()=>{
        if(showMenu){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    },[showMenu])
    
    const handleClick = () =>{
        setShowMenu(!showMenu)
    }

    const fbClick = () => {
        const url = "https://www.promsocdays.be"
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
        'facebook-share-dialog',
        'width=800,height=600'
        );
        return false;
    }

    const twClick = () => {
        const url = "https://www.promsocdays.be"
        window.open('https://twitter.com/share?url=' + url,
        'twitter-share-dialog',
        'width=800,height=600'
        );
        return false;
    }

    return ( 
        <>
         <div className={`menu-mobile ${showMenu ? "open-menu" : null}`}>
                <ul>
                    <li><Link to="/" onClick={handleClick}>Accueil</Link></li>
                    <li><Link to="/presentation" onClick={handleClick}>Présentation</Link></li>
                    <li><HashLink to="/#schools" onClick={handleClick}>Etablissements</HashLink></li>
                </ul>
            </div>
            <header>
                <div id="header-info">
                    <div className="wrapper">
                        <div id="header-social">
                            <img src="/images/share.png" alt="Share Logo" />
                            <img src="/images/facebook.png" alt="Logo FaceBook" onClick={fbClick} id="fb-link" />
                            <img src="/images/twitter.png" alt="Twitter logo" onClick={twClick} id="tw-link" />  
                        </div>
                    </div>
                </div>
                <div id="myheader">
                    <div className="wrapper">
                        <Link to='/' id="logo">PROMSOC<span>'</span>DAYS</Link>
                        <nav>
                            <ul>
                                <li><Link to="/">Accueil</Link></li>
                                <li><Link to="/presentation">Présentation</Link></li>
                                <li><HashLink to="/#schools">Etablissements</HashLink></li>
                            </ul>
                            <div id="burger" onClick={handleClick} className={showMenu ? "activeMenu" : null}>
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
     );
}
 
export default Header;